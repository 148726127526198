import React, { useEffect, useState, useCallback, useMemo } from "react";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { Modal } from 'react-responsive-modal';
import DigitalSignature from "./DigitalSignature";
import { Puff } from 'react-loader-spinner';
import { useParams } from "react-router-dom";
import SuccessMpaSign from '../SuccessMpaSign'; 
import logo from "../assests/Logo.png";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import BeforeSignedFile from "./BeforeSignedFile";

const ViewOnboarding = () => {
    const params = useParams();
    const [isSigned, setIsSigned] = useState(true);

    const [open, setOpen] = useState(false);  
    const [signatureLoading, setSignatureLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [linkExpired, setLinkExpiered] = useState(false);

    const [success, setSuccess] = useState(false);  
    const handleOpen = () => {
        setOpen(true);  
    };

    const handleClose = () => {
        setOpen(false);  
    };

    useEffect(() => {
        if (signatureLoading) handleClose();
    }, [signatureLoading]);

    const handleAddSignature = () => {
        handleOpen();  
    };


    // const handleSuccess = () => {
    //     setSuccess(true);  
    // };

    if (success) {
        return <SuccessMpaSign setSuccess={setSuccess} linkExpired={linkExpired}/>;
    }
    return (
        <>
            <div className="agreement-main">
                <div className="client-logo">
                    <img src={logo} alt="logo" />
                </div>
                <h2>Merchant Processing Application And Agreement</h2>
                <p>Please review and sign the following documents:</p>
                <p><strong>MPA (Merchant Processing Agreement):</strong> This agreement outlines your fees, charges, and the information submitted via API.</p>
                <p><strong>Program Guide:</strong> Ensure you read this guide in full at some point. It describes the terms of our merchant processing services and provides answers to common questions about your agreements with the Bank, Processor, and TeleCheck.</p>
                <p>Once you have completed your review and signed both documents, you may exit at your convenience.</p>

                <div className="pdf-main" style={{display:"flex",gap:"40px",flexDirection:"column"}}>
                    <BeforeSignedFile setIsSigned={setIsSigned} setLoading={setLoading} isSigned={isSigned} success={success} setSuccess={setSuccess} setLinkExpiered={setLinkExpiered}/>
                </div>

                {!isSigned&& <ul className="button-group">
                    {!params?.pdf_name?.includes('sign') && (
                        <li className="primary-btn" onClick={handleAddSignature}>Complete</li>
                    )}
                </ul>}
            </div>

            <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
                <DigitalSignature setSignatureLoading={setSignatureLoading} setSuccess={setSuccess}  /> 
            </Modal>

            {(signatureLoading || loading) && (
                <div className="loader-container">
                    <div className="loader-background"></div> 
                    <div className="loader-content">
                        <Puff color="#ffffff" height={100} width={100} />
                    </div>
                </div>
            )}
        </>
    );
};

export default ViewOnboarding;
