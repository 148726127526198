import React from 'react';

const SuccessMpaSign = ({ setSuccess, linkExpired }) => {
  return (
    <div className='row' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div className="container all-steps final">
        {!linkExpired ? (
          <div className='succ-regi'>
            <div className='text'>
              <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_8413_40551)">
                  <circle cx="25" cy="25" r="25" fill="url(#paint0_linear_8413_40551)" />
                  <rect x="10" y="25.3369" width="7" height="16.8269" rx="3.5" transform="rotate(-44.9454 10 25.3369)" fill="url(#paint1_linear_8413_40551)" />
                  <rect x="34.9609" y="14" width="7" height="25.6115" rx="3.5" transform="rotate(42.5801 34.9609 14)" fill="url(#paint2_linear_8413_40551)" />
                </g>
                <defs>
                  <linearGradient id="paint0_linear_8413_40551" x1="4.86111" y1="-3.47222" x2="45.8333" y2="54.8611" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="0.127051" stopColor="#C7A4FF" stopOpacity="0.59" />
                    <stop offset="0.572861" stopColor="#EEE4FF" stopOpacity="0.56" />
                    <stop offset="0.885175" stopColor="white" />
                  </linearGradient>
                  <linearGradient id="paint1_linear_8413_40551" x1="18.445" y1="34.5703" x2="13.1831" y2="36.4539" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CFBBEF" />
                    <stop offset="1" stopColor="#B697E7" />
                  </linearGradient>
                  <linearGradient id="paint2_linear_8413_40551" x1="41.9609" y1="26.8058" x2="34.0968" y2="27.5693" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CFBBEF" />
                    <stop offset="1" stopColor="#B697E7" />
                  </linearGradient>
                  <clipPath id="clip0_8413_40551">
                    <rect width="50" height="50" rx="7.3873" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <h1>Congratulations</h1>
              <p>Your application has been completed. <br /> We will be in touch by email once you have been approved.</p>
            </div>
            {window.location.pathname.includes("read-pdf") ? (
              <button onClick={() => setSuccess(false)}>View Documents</button>
            ) : (
              <button>Close</button>
            )}
          </div>
        ) : (
          <div className='expired-link'>
            <div className='text text-center'>
              <svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="10" stroke="red" strokeWidth="2" fill="none" />
                <line x1="8" y1="8" x2="16" y2="16" stroke="red" strokeWidth="2" />
                <line x1="8" y1="16" x2="16" y2="8" stroke="red" strokeWidth="2" />
              </svg>
              <h1>Link is expired!</h1>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SuccessMpaSign;
